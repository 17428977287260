import * as React from "react"

import { Link } from "gatsby"
import { FiLink } from 'react-icons/fi'

const Links = ({ heading, posts = [] }) => {
  return (
    <div className="c-links">
      <div className="c-links__wrapper">
        <h2 className="c-links__heading">{heading}</h2>
        <ul className="c-links__items">
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li className="c-links__item" key={post.fields.slug}>
                <Link to={post.fields.slug}><FiLink />{title}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Links
