import * as React from "react"
import TheButton from "../components/button"

const TextImage = ({
  imgUrl,
  imgAlt = "",
  heading,
  text,
  btn,
  asHeader = false,
}) => {
  const HeadingTag = asHeader ? 'h1' : 'h2'
  return (
    <div className={(`c-text-image`, (asHeader && "c-text-image--as-header") || '')}>
      <div className="c-text-image__wrapper">
        <div className="c-text-image__image">
          <img src={imgUrl} alt={imgAlt} className="c-text-image__img" />
        </div>
        <div className="c-text-image__content">
          <HeadingTag className="c-text-image__hading">{heading}</HeadingTag>
          <div
            className="c-text-image__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {btn && (
            <div className="c-text-image__btn-wrapper">
              <TheButton type="link" to={btn.to}>
                {btn.text}
              </TheButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TextImage
