import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import TheButton from "../components/button"
import { BsTelephone } from "react-icons/bs"
import { GiHamburgerMenu } from "react-icons/gi"

const Topbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => {
    console.log(isOpen)
    setIsOpen(!isOpen)
  }
  return (
    <header className="c-topbar">
      <div className="c-topbar__wrapper">
        <div className="c-topbar__logo">
          <Link to="/" className="c-topbar__logo-link">
            Ogrzewanie Brodnica
          </Link>
          <div className="c-topbar__hamburger">
            <TheButton onClick={() => toggleMenu()}>
              <GiHamburgerMenu />
            </TheButton>
          </div>
        </div>
        <nav className={`c-topbar__nav` + ((isOpen && " is-active") || "")}>
          <Link to="/uslugi">Usługi grzewcze w Brodnicy</Link>
          <Link to="/kontakt">Kontakt</Link>
          <TheButton type="link" to="tel:+48501630051">
            <BsTelephone /> +48 501 630 051
          </TheButton>
        </nav>
      </div>
    </header>
  )
}

export default Topbar
