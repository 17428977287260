import * as React from "react"
import { Link } from "gatsby"

const Button = ({ type = "button", to, children, onClick }) => {
  return (
    <div className="c-button">
      {(type === "button" && (
        <button onClick={onClick}>{children}</button>
      )) ||
        (to.startsWith("tel") ? (
          <a href={to}>{children}</a>
        ) : (
          <Link to={to}>{children}</Link>
        ))}
    </div>
  )
}

export default Button
