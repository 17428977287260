import * as React from "react"
import { Link } from "gatsby"
import Topbar from "../components/topbar"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <Topbar />
      <main>{children}</main>
      <footer className="c-footer">
        <p>Wszelkie prawa zastrzerzone © {new Date().getFullYear()} Ogrzewanie Brodnica</p>
        <p><Link to="/credits">Credits</Link></p>
      </footer>
    </div>
  )
}

export default Layout
