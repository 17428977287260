import * as React from 'react'

import TheButton from './button'
const CTA = () => {
  return (
    <div className='c-cta'>
      <div className="c-cta__wrapper">
        <h2 className="c-cta__heading">Potrzebujesz pomocy z ogrzewaniem?</h2>
        <TheButton type="link" to="/kontakt">Kontakt</TheButton>
      </div>
    </div>
  )
}

export default CTA
